import "../../style.css";
import "../css/index.css";
import "./polyfills";
import { createInertiaApp } from "@inertiajs/vue3";
import { createApp, h } from "vue";

import common from "./vue/app.js";

// Silence Fullstory "ResizeObserver loop completed with undelivered notifications" error
window.addEventListener("error", function (event) {
    if (event.message.includes("ResizeObserver loop")) {
        // Prevent the error from propagating further
        event.preventDefault();
    }
});

createInertiaApp({
    id: "app",
    resolve: (name) => {
        const pages = import.meta.glob("./vue/pages/*.vue", { eager: true });
        const path = `./vue/pages/${name}.vue`;
        return pages[path] ?? pages["./vue/pages/NotFound.vue"];
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        app.use(common, props.initialPage.props);
        app.use(plugin);
        app.mount(el);
    },
});
