import mitt from "mitt";
import { reactive, ref } from "vue";
import Vue3Storage, { StorageType } from "vue3-storage";
import { router } from "@inertiajs/vue3";

export default {
    /*
     * `install` only runs once, and is passed the props of the page that is
     * first rendered at page load. These props are used as the initial value
     * for some values that are mutable (user details, org details, etc.), and
     * for others that are immutable (events url, etc).
     *
     * All pages in ~vue/pages define their page props in the `props` object.
     * A prop is only referenced here if it is expected to be constant across
     * pages, as if they were global variables. These are usual set in
     * `vpoc/vpoc/middleware.py::inertia_shared_context_middleware`.
     *
     * If a prop is to be hydrated in subsequent navigation, it should be added
     * to `HYDRATABLE_PROPS`.
     */
    install: (app, initialProps) => {
        const { event_url, feedback_mailto, start_chat_url, loading_image_src, user_details, organization_details, staff_links } = initialProps;

        const eventContext = ref({});
        const userDetails = ref(user_details || {});
        const organizationDetails = ref(organization_details || {});

        /*
         * Each tuple in the array below corresponds to:
         * [0] The prop key
         * [1] The prop setter function
         * These functions can be used to set any value that needs updating.
         */
        const HYDRATABLE_PROPS = [
            [
                "user_details",
                (value) => {
                    userDetails.value = value;
                },
            ],
            [
                "organization_details",
                (value) => {
                    organizationDetails.value = value;
                },
            ],
        ];

        function hydrateProps(props) {
            for (const [key, setter] of HYDRATABLE_PROPS) {
                if (key in props) {
                    setter(props[key]);
                }
            }
        }

        const sendEvent = async function (eventType, data) {
            const response = await fetch(event_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    event_type: eventType,
                    ...data,
                }),
            });

            const body = await response.json();

            if (!response.ok) {
                return Promise.reject(body);
            }

            return body;
        };

        const emitter = mitt();

        // Set up storages
        app.use(Vue3Storage, { namespace: "nadia_", storage: StorageType.Local });

        // Set up emitters
        app.config.globalProperties.emitter = emitter;

        // Set up global mutable variables
        app.config.globalProperties.$userDetails = userDetails;
        app.config.globalProperties.$organizationDetails = organizationDetails;

        // Set up global constants with server side variables.
        app.config.globalProperties.$loadingImageSrc = loading_image_src;
        app.config.globalProperties.$startChatUrl = start_chat_url;
        app.config.globalProperties.$staffLinks = staff_links;
        app.config.globalProperties.$feedbackMailto = feedback_mailto;

        // Helper methods for sending events.
        // Attach any properties that will be sent in every $sendEvent call for a page
        app.config.globalProperties.$setEventContext = (context) => {
            eventContext.value = context;
        };
        app.config.globalProperties.$sendEvent = async (event_type, data) => {
            return sendEvent(event_type, { ...eventContext.value, ...data });
        };

        // Make $sendEvent available to composition API components, as in: `const sendEvent = inject("sendEvent")`
        app.provide("sendEvent", app.config.globalProperties.$sendEvent);
        app.provide("globalProperties", app.config.globalProperties);

        router.on("navigate", (event) => {
            const { props } = event.detail.page;
            hydrateProps(props);
        });
    },
};
